.dots {
  position: absolute;
  bottom: 32px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 12;
  transition: opacity 500ms;
}

.dots_hidden {
  opacity: 0;
}

.dots__item {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin-right: 1.5rem;
  width: 12px;
  height: 12px;
}

.dots__item a {
  display: block;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  border-radius: 100%;
  border: 2px solid white;
  position: absolute;
}

.dots__item:last-child {
  margin-right: 0;
}

.dots__item_active a {
  background-color: white;
}

@media screen and (max-width: 1023px) {
  .dots {
    position: absolute;
    bottom: 58px;
    left: auto;
    right: 0;
    transform: translateX(0);
    z-index: 12;
  }

  .dots__item {
    margin-right: 2rem;
    width: 18px;
    height: 18px;
  }
}

@media screen and (max-width: 720px) {
  .dots {
    bottom: 37px;
    right: 0;
    text-align: right;
    transform: none;
  }

  .dots__item {
    margin-right: 1.6rem;
    width: 1.6rem;
    height: 1.6rem;
  }
}
