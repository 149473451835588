.layout {
  position: relative;
  padding: 0 12rem;
  margin: 0 auto;
  overflow: hidden;
}

.layout__container {
  position: relative;
  height: 100vh;
  min-height: 600px;
  z-index: 10;
}

.layout-page {
  transition: opacity 1s ease;
  opacity: 0;
  overflow-x: hidden;

  padding: 0 7rem;
  margin: 0 -7rem;
}

.layout-page_mounted {
  opacity: 1;
}

@media screen and (min-width: 1700px) {
  .layout {
    max-width: 1600px;
    margin: 0 auto;
    padding: 0;
  }
}

@media screen and (max-width: 1023px) {
  .layout {
    padding: 0 5rem;
  }

  .layout-page {
    padding: 0 5rem;
    margin: 0 -5rem;
  }

  .layout__container {
    min-height: 960px;
  }
}

@media screen and (max-width: 720px) {
  .layout {
    padding: 0 2.5rem 0 2.5rem;
  }

  .layout-page {
    padding: 0 2.5rem;
    margin: 0 -2.5rem;
  }

  .layout__container {
    min-height: 0;
    height: auto;
    min-height: 0;
  }
}

.header-enter {
  opacity: 0;
}

.header-enter-active {
  opacity: 1;
  transition: opacity 350ms;
}

.header-exit {
  opacity: 1;
}

.header-exit-active {
  opacity: 0;
  transition: opacity 350ms;
}

.footer-enter {
  opacity: 0;
}

.footer-enter-active {
  opacity: 1;
  transition: opacity 350ms;
}

.footer-exit {
  opacity: 1;
}

.footer-exit-active {
  opacity: 0;
  transition: opacity 350ms;
}
