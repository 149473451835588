.footer {
  position: absolute;
  bottom: 22px;
  left: 0;
  right: 0;
  min-height: 35px;
  padding: 0 8rem;
  z-index: 11;
}

body.modal-open .footer {
  z-index: 3;
}

.footer a {
  text-decoration: none;
  color: inherit;
}

.footer-logo {
  position: absolute;
  left: 0;
  top: 0;
  width: 3.5rem;
  cursor: pointer;
}

.footer-menu {
  line-height: 1;
}

.footer-menu__item {
  margin-right: 1.5rem;
  display: inline-block;
  color: white;
  font-weight: 500;
  vertical-align: middle;
  cursor: pointer;
  font-size: 1.5rem;
}

.footer-menu__item-active {
  font-style: italic;
  color: #66CCCC;
}

.footer-menu__item-active a:hover {
  border-bottom: none;
}


.footer-menu__item:last-child {
  margin-right: 0;
}

.footer-copyright {
  position: fixed;
  bottom: 16px;
  right: 16px;
  width: 40%;
  font-size: 12px;
  font-weight: 500;
  font-family: Roboto;
  color: #828181;
}

.footer-copyright.footer-copyright__right {
  right: 16px;
}

.footer-copyright.footer-copyright__left {
  right: auto;
  left: 16px;
}

.footer-copyright__col {
  flex: 1 1 50%;
  padding-right: 20px;
  line-height: 1.3;
}


@media screen and (min-width: 1700px) {
  .footer {
    padding: 0 110px;
  }

  .footer-menu__item {
    font-size: 15px;
    margin-right: 16px;
  }

  .footer-logo {
    width: 38px;
  }
}

@media screen and (min-width: 1100px) {
  .footer-logo {
    width: 35px;
  }
}

@media screen and (max-width: 1023px) {
  .footer {
    bottom: 23px;
    padding: 0 8rem;
    margin-bottom: 28px;
  }

  .footer-copyright {
    display: none;
  }

  .footer-copyright__col {
    display: block;
    margin-bottom: 10px;
    font-size: 10px;
  }

  .footer-copyright__col:last-child {
    margin-bottom: 0;
  }

  .footer-menu {
    display: none;
  }

  .footer-menu__item {
    margin-right: 1.3rem;
    font-size: 1.5rem;
  }

  .footer-logo {
    position: absolute;
    left: 0px;
    top: -15px;
    width: 55px;
  }
}

@media screen and (max-width: 720px) {
  .footer {
    position: absolute;
    bottom: 19px;
    height: 55px;
    left: 0;
    right: 0;
    margin-top: 0;
    padding: 0;
    margin-bottom: 0;
  }

  .footer-logo {
    top: 0;
  }

  .footer-menu {
    display: none;
  }

  .footer-copyright {
    display: none;
  }
}
