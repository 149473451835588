.header {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  color: white;
  z-index: 11;
}

.header a {
  text-decoration: none;
  color: inherit;
}

.header a:hover {
  border-bottom: 1px solid;
}

.header-logo {
  left: 0;
  width: 21rem;
  max-width: 230px;
  cursor: pointer;
}

.header-logo_mobile {
  display: none;
}

.header__container {
  position: absolute;
  left: 0;
  right: 0;
  top: 3rem;
  height: 24px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-content: flex-end;
  z-index: 5;
}

.header-menu-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
}

.header-menu {
  line-height: 1;
}

.header-menu__item {
  margin-right: 1.5rem;
  display: inline-block;
  color: white;
  font-weight: 500;
  vertical-align: middle;
  cursor: pointer;
  font-size: 1.5rem;
}

.header-sub-menu .header-menu__item {
  font-size: 1.4rem;
}

.header-menu__item-active {
  font-style: italic;
  color: #66CCCC;
}

.header-menu__item-active a:hover {
  border-bottom: none;
}


.header-menu__item:last-child {
  margin-right: 0;
}

.header-auth {
  position: relative;
  line-height: 1;
}

.header-auth__item {
  font-size: 1.6rem;
  text-transform: uppercase;
  margin-right: 3rem;
  display: inline-block;
  vertical-align: middle;
}

.header-auth__item a:hover {
  border-bottom: 1px solid;
}

.header-auth__item:last-child {
  margin-right: 0;
}

.header-dropdown {
  position: relative;
}

.header-dropdown ul {
  position: absolute;
  top: 100%;
  right: 0;
  left: -10px;
  padding: 18px;
  background: #000000;
  transform: translate(10px, 10px);
}

.header-dropdown ul:before {
  content: '';
  display: block;
  position: absolute;
  top: -5px;
  right: 10px;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid #000000;
}

.header-dropdown li {
  display: block;
  margin-bottom: 9px;
}

.header-dropdown li:last-of-type {
  margin-bottom: 0;
}

.header-dropdown a {
  text-transform: none;
  color: #ffffff;
  font-size: 1.6rem;
}

.header-dropdownAction {
  display: flex;
  align-items: center;
  font-size: 1.6rem;
  text-transform: uppercase;
  cursor: pointer;
}

.header-dropdownAction:after {
  content: '';
  display: block;
  width: 10px;
  height: 6px;
  margin-left: 5px;
  background: url('./images/arrow-down.svg') no-repeat center;
  background-size: 10px 6px;
}

.header-dropdownAction:hover {
  border-bottom: 1px solid;
}

.header-dropdownAction:hover + ul {
  top: calc(100% - 1px);
}

@media screen and (max-width: 1023px) {
  .header-logo {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 246px;
    max-width: none;
  }

  .header__container {
    top: 0;
    height: 65px;
    left: 0;
    right: 0;
    position: absolute;
  }

  .header-menu {
    display: none;
  }

  .header-sub-menu {
    display: none;
  }

  .header-auth {
    display: none;
  }
}

@media screen and (max-width: 720px) {
  .header__container {
    position: absolute;
    height: auto;
    top: 25px;
  }

  .header-logo {
    display: none;
  }

  .header-logo_mobile {
    display: block;
    max-width: 300px;
    width: 20rem;
  }
}
